// 
// Global
// 

// Scroll padding for all scroll targets on page used with
// native CSS smooth scrolling
// 
// https://caniuse.com/?search=scroll-padding

html {
    height: 100%;
    scroll-padding-top: calc(#{$navbar-height} - 1px);
}

body {
    width: 100%;
    height: 100%;
}

//- Page section styling
.page-section {
    padding: 8rem 0;
}

// Custom utilities for this theme
.text-white-75 {
    color: fade-out($white, 0.25) !important;
}
