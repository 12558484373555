// 
// Colors
// 

//- Override and add to color system
$orange: #f4623a;
$brown: #5c4d42;

//- Override primary color
$primary: $orange;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 3;
