// 
// Dividers
// 

hr.divider {
    height: 0.2rem;
    max-width: 3.25rem;
    margin: 1.5rem auto;
    background-color: $primary;
    opacity: 1;
}

hr.divider-light {
    background-color: $white;
}
